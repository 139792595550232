import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'

import Header from '../../components/header'
import Columns from '../../components/columns'
import Sidebar from '../../components/sidebar'
import Quote from '../../components/quote'
import Video from '../../components/video'

import FinancialPlanningNav from '../../components/financial-planning-nav'

import image from '../../images/samples/retirement3.png'
import videos from '../../lib/videos'

const InvestmentServicesPage = () => (
  <Layout>
    <Helmet
      title={'Investment Services'}
      meta={[
        {
          name: 'description',
          content:
            // tslint:disable-next-line:max-line-length
            'To offer you a complete range of money management capabilities, David Templeton, RFC, is affiliated with Royal Fund Management, a fee-only investment advisory firm that does not sell financial products or receive commissions. This independent fee-only relationship allows you to rest assured there are no conflicts created by commissions and no product bias.',
        },
        {
          name: 'keywords',
          content:
            // tslint:disable-next-line:max-line-length
            'Adage Financial Consultants, David Templeton, Little Rock Arkansas, Financial Advisor, Financial Planner, 401k, Safe Money, Annuity',
        },
      ]}
    />
    <Header headline={'Investment Services'} image={image} />
    <Columns background>
      <Sidebar>
        <FinancialPlanningNav />
      </Sidebar>
      <div className="marketing__column">
        <Quote text={'Your best interest is our best interest'} />
        <Video youTubeUrl={videos.pages.investmentServices.url} />
        <p>
          To offer you a complete range of money management capabilities, David
          Templeton, RFC, is affiliated with{' '}
          <a href="https://royalfundmanagement.com/">Royal Fund Management</a>,
          a fee-only investment advisory firm that does not sell financial
          products or receive commissions. This independent fee-only
          relationship allows you to rest assured there are no conflicts created
          by commissions and no product bias.
        </p>

        <p>
          By serving as an independent fiduciary, our compensation is directly
          correlated to the value of your managed account. Simply put, the only
          way for us to make money is by helping you grow your account.
        </p>
        <p>
          <a href="https://royalfundmanagement.com/">Royal Fund Management</a>{' '}
          employs a diversified strategy utilizing no-load mutual funds and
          individual equities. Several model portfolios are available to meet a
          client’s specific. Thousands of funds are analyzed for:
        </p>
        <ul className="marketing__checklist">
          <li>Performance</li>
          <li>Expense Ratios</li>
          <li>Managent Tenure</li>
          <li>Risk / Reward</li>
          <li>Other Ratios Necessary To Evaluate Potential</li>
        </ul>
        <p>
          Diversification among asset classes is a key to our strategy but,
          cyclical opportunities and sector momentum plays are utilized to
          potentially enhance portfolio value.
        </p>
      </div>
    </Columns>
  </Layout>
)

export default InvestmentServicesPage
